import * as React from 'react'
import styled from '@emotion/styled'
import palette from '../palette'
import { respondTo } from '../dimensions'

const SuperText = styled.div`
	color: ${({ inverted }) => (inverted ? '#FFFFFF' : palette.midnight.m1)};
	font-weight: 500;
	margin-bottom: 16px;
	z-index: 1;
`
const Title = styled.h1`
	color: ${({ theme }) => (theme === 'orange' ? '#FFFFFF' : palette.sunrise.s1)};
	margin-bottom: 16px;
	z-index: 1;
	${respondTo.xsOnly} {
		font-size: 50px;
	}
`
const Seperator = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${({ theme }) => (theme === 'orange' ? '#FFFFFF' : palette.midnight.m5)};
	margin-bottom: 24px;
	z-index: 1;
`

const Wrapper = styled.div`
	width: 100%;
	pointer-events: none;
	z-index: 1;
`

export default function PageTitle({
	superText,
	children,
	seperator = true,
	inverted = true,
	theme,
}) {
	return (
		<Wrapper>
			<div
				data-sal="slide-up"
				data-sal-delay="300"
				data-sal-easing="ease-in-out"
				data-sal-duration="500"
			>
				<SuperText inverted={inverted}>{superText}</SuperText>
				<Title theme={theme}>{children}</Title>
			</div>

			{seperator && <Seperator theme={theme} />}
		</Wrapper>
	)
}
