import React from 'react';
import ReactParticles from "react-tsparticles";
import styled from '@emotion/styled';

const ParticleWrapper = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0;
	& > div {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 0;
	}
`
const images = [
	{
		height: 200,
		width: 200,
		src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='56.245' height='52.878' viewBox='0 0 56.245 52.878'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23f7ba4a;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M-528.239,3341.626a32,32,0,0,0,45.126-3.368l-48.494-41.759A32,32,0,0,0-528.239,3341.626Z' transform='translate(539.358 -3296.499)'/%3E%3C/svg%3E",
	},
	{
		height: 200,
		width: 200,
		src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54.624' height='54.623' viewBox='0 0 54.624 54.623'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23295769;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M-658.124,3316.96a32,32,0,0,0,0,45.252l45.252-45.252A32,32,0,0,0-658.124,3316.96Z' transform='translate(667.496 -3307.589)'/%3E%3C/svg%3E%0A",
	},
	{
		height: 200,
		src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='49.57' height='44.835' viewBox='0 0 49.57 44.835'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23f7ba4a;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M-655.931,3427.4l49.57,40.1-4.735-44.835Z' transform='translate(655.931 -3422.668)'/%3E%3C/svg%3E",
		width: 200
	},
	{
		height: 200,
		src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='49.57' height='44.835' viewBox='0 0 49.57 44.835'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23295769;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M-529.931,3185.4l49.57,40.1-4.735-44.835Z' transform='translate(529.931 -3180.668)'/%3E%3C/svg%3E",
		width: 200
	},
	{
		height: 200,
		src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50.472' height='44.715' viewBox='0 0 50.472 44.715'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23295769;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M-531.617,3592.157l50.472-38.958-44.715-5.757Z' transform='translate(531.617 -3547.442)'/%3E%3C/svg%3E",
		width: 200
	},
	{
		height: 200,
		width: 200,
		src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31.879' height='63.759' viewBox='0 0 31.879 63.759'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23002f47;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M-431,3313.241V3377l31.879-31.879Z' transform='translate(431 -3313.241)'/%3E%3C/svg%3E",
	},
	{
		height: 200,
		width: 200,
		src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54.624' height='54.623' viewBox='0 0 54.624 54.623'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23295769;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M-578.124,3500.96a32,32,0,0,0,0,45.252l45.252-45.252A32,32,0,0,0-578.124,3500.96Z' transform='translate(587.496 -3491.589)'/%3E%3C/svg%3E",
	},
	
	{
		height: 200,
		width: 200,
		src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='59.332' height='48.646' viewBox='0 0 59.332 48.646'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23002e3f;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M-492.93,3442.969l-54.658,33.287a32,32,0,0,0,43.973,10.685A32,32,0,0,0-492.93,3442.969Z' transform='translate(547.588 -3442.969)'/%3E%3C/svg%3E",
	},
]


export default function Particles({ id, count = 8 }) {
	return (
		<ParticleWrapper>
			<ReactParticles
				id={id}
				options={{
					fullScreen: true,
					pauseOnOutsideViewport: true,
					fpsLimit: 60,
					interactivity: {
						detectsOn: "canvas",
						events: {
							onClick: {
								enable: false,
							},
							onHover: {
								enable: true,
								mode: "bubble",
								parallax: {
									enable: true,
									smooth: 100,
									force: 100,
								}
							},
							resize: true,
						},
						modes: {
							attract: {
								duration: 3,
								distance: 200,
								easing: 'ease-out-quar'
							},
							"slow": {
								"radius": 100,
								"factor": 3
							},
							"grab": {
								duration: 3,
								reduceDuplicates: true,
								"distance": 300,
								"line_linked": {
									"opacity": 0
								}
							},
							bubble: {
								size: 30,
								duration: 0.9
							},
							bounce: {
								
							}
						},
					},

					particles: {
						// opacity: {
						// 	value: 0.9
						// },
						reduceDuplicates: true,
						move: {
							direction: "none",
							enable: true,
							outMode: "bounce",
							random: false,
							speed: 0.2,
							"attract": {
								"enable": false,
								"rotateX": 600,
								"rotateY": 1200
							}
						},
						number: {
							value: count,
						},
						shape: {
							type: 'images',
							images: images.slice(0, count),
						},
						size: {
							value: 30,
						},
					},
					detectRetina: true,
				}}
			/>
		</ParticleWrapper>
	)
}