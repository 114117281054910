import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import palette, { background } from '../palette'
import ProgressBar from 'react-scroll-progress-bar'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { components, respondTo } from '../dimensions'
import { navigate } from 'gatsby'

var color1 = '#002E3F'
var color2 = background.light
var gutter = '36px'

const Root = styled.div`
	:root {
		--replace-offset: 50%;
		--replace-offset-2: calc((100% - var(--replace-offset)) * -1);
	}
	/* align content above each other without absolute */
	.js-replace {
		display: grid;
	}

	.js-replace__item {
		grid-row: -1 / 1;
		grid-column: -1 / 1;
		overflow: hidden;
		will-change: transform;
	}

	/* item to replace with */
	.js-replace__item {
		transform: translateY(calc(var(--replace-offset) * 1));
	}
	.js-replace__content {
		/* fixes problem with calculating correct height in js */
		will-change: transform;

		transform: translateY(calc(var(--replace-offset) * -1));
	}

	/* previous replace item*/
	.js-replace__item--active {
		transform: translateY(calc(calc((100% - var(--replace-offset)) * -1) * 1));
	}
	.js-replace__item--active .js-replace__content {
		transform: translateY(calc(calc((100% - var(--replace-offset)) * -1) * -1));
	}

	/* REVERSE ANIMATION */
	.js-replace--reverse .js-replace__item {
		transform: translateY(calc(calc((100% - var(--replace-offset)) * -1) * 1));
	}
	.js-replace--reverse .js-replace__content {
		transform: translateY(calc(calc((100% - var(--replace-offset)) * -1) * -1));
	}

	/* previous replace item*/
	.js-replace--reverse .js-replace__item--active {
		transform: translateY(calc(var(--replace-offset) * 1));
	}
	.js-replace--reverse .js-replace__item--active .js-replace__content {
		transform: translateY(calc(var(--replace-offset) * -1));
	}
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: transparent;
	height: ${components.header.height}px;
	padding: 0 36px;
	position: fixed;
	top: 36px;
	left: 0;
	right: 100px;
	z-index: 99;
	svg {
		overflow: visible;
	}
	${respondTo.xsOnly} {
		top: 24px;
		padding: 0 24px;
	}
	button {
		padding: 0;
	}
`

const HeaderLogo = styled.div``

const Section = styled.section`
	padding-bottom: ${props => (props.flush ? '0' : gutter)};
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
`

const Main = styled.main`
	section:nth-of-type(odd) {
		background-color: ${color2};
	}
	section:nth-of-type(even) {
		background-color: ${color1};
	}
	section:nth-of-type(3),
	section:nth-of-type(5) {
		background-color: ${palette.sunrise.s1};
	}
	section:nth-of-type(6) {
		background-color: ${color2};
	}
`

const isWindowUnavailable = typeof window === 'undefined'
const requestFrameFallback = callback => {
	setTimeout(() => {
		return callback()
	}, 0)
}

const useElementChangeOnScroll = () => {
	const requestFrame = useCallback(
		callback => {
			if (isWindowUnavailable) {
				requestFrameFallback(callback)
			} else {
				return (
					window.requestAnimationFrame(callback) ||
					window.wcallbackbkitRequestAnimationFrame(callback) ||
					window.mozRequestAnimationFrame(callback) ||
					window.msRequestAnimationFrame(callback) ||
					window.oRequestAnimationFrame(callback) ||
					requestFrameFallback(callback)
				)
			}
		},
		[isWindowUnavailable]
	)

	const [currentSectionIndex, setCurrentSectionIndex] = useState(0)
	const [lastSection, setLastSection] = useState(false)
	const [isOrange, setIsOrange] = useState(false)
	var lastPosition = -1
	var replaceItemTop = -1
	var replaceItemBottom = -1
	var replaceItemHeight = -1

	const loop = () => {
		var sections = document.querySelectorAll('.section')
		var replaceContainer = document.querySelectorAll('.js-replace')
		var replaceItem = document.querySelectorAll('.js-replace__item')

		if (replaceItem.length > 0) {
			replaceItemTop = parseInt(replaceContainer[0].getBoundingClientRect().top)
			replaceItemHeight = replaceItem[0].offsetHeight
			replaceItemBottom = replaceItemTop + replaceItemHeight
		}

		var sectionTop = -1
		var sectionBottom = -1
		var currentSection = -1

		if (lastPosition == window.pageYOffset) {
			requestFrame(loop)
			return false
		} else {
			lastPosition = window.pageYOffset
			sections.forEach((section, index) => {
				sectionTop = parseInt(section.getBoundingClientRect().top)
				sectionBottom = parseInt(section.getBoundingClientRect().bottom)
				const isSectionAboveReplaceArea = replaceItemTop >= sectionTop
				const isSectionOutsideReplaceArea =
					sectionTop <= replaceItemBottom && sectionBottom > replaceItemTop
				const isSectionOverlappingWithReplaceArea =
					replaceItemTop < sectionTop && sectionTop <= replaceItemBottom
				if (isSectionOutsideReplaceArea) {
					currentSection = section.classList.contains('section--bg')
					setCurrentSectionIndex(index)
					if (currentSection) {
						replaceContainer[0].classList.remove('js-replace--reverse')
					} else {
						if (section.classList.contains('section--orange')) {
							setIsOrange(true)
						} else {
							setIsOrange(false)
						}
						replaceContainer[0].classList.add('js-replace--reverse')
					}
				}
				if (isSectionOverlappingWithReplaceArea) {
					if (currentSection != lastSection) {
						document.documentElement.style.setProperty(
							'--replace-offset',
							(100 / replaceItemHeight) * parseInt(sectionTop - replaceItemTop) + '%'
						)
					}
				}
				if (isSectionAboveReplaceArea) {
					document.documentElement.style.setProperty('--replace-offset', 0 + '%')
					setLastSection(currentSection)
				}
			})
		}
		requestFrame(loop)
	}
	useEffect(() => {
		if (isWindowUnavailable) return false
		const handleResize = () => {
			loop()
		}
		loop()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return { currentSectionIndex, isOrange }
}

const getProgressBarColor = activeSection => {
	switch (activeSection) {
		case 0:
		default:
			return palette.midnight.m1
		case 1:
			return palette.sunrise.s1
		case 2:
		case 4:
			return '#FFFFFF'
		case 3:
			return palette.sunrise.s1
	}
}

const getSectionClass = index => {
	switch (index) {
		case 0:
		default:
			return ''
		case 1:
			return 'section--bg'
		case 2:
			return 'section--orange'
		case 3:
			return 'section--bg'
		case 4:
			return 'section--orange'
	}
}

export const ElementChange = ({ itemsToReplace, replacingItems, sections }) => {
	const { currentSectionIndex: activeSection, isOrange } = useElementChangeOnScroll()
	const itemToReplace = isOrange ? itemsToReplace.orange : itemsToReplace.normal
	const handleMenuItemSelection = () => {
		if (window.location.pathname !== '/') {
			navigate('/#main')
		} else {
			scrollTo('#main')
		}
	}
	return (
		<Root>
			<ProgressBar bgcolor={getProgressBarColor(activeSection)} />
			<Main className="main">
				{sections.map((section, index) => (
					<Section
						flush
						className={`section ${getSectionClass(index)}`}
						key={index + 'pageSection'}
					>
						{section}
					</Section>
				))}
			</Main>
			<Header className="header">
				<HeaderLogo className="header__logo js-replace">
					<div className="js-replace__item">
						<div className="js-replace__content">
							<button onClick={handleMenuItemSelection}>
								{replacingItems.light}
							</button>
						</div>
					</div>
					<div className="js-replace__item  js-replace__item--active">
						<div className="js-replace__content">
							<button onClick={handleMenuItemSelection}>{itemToReplace}</button>
						</div>
					</div>
				</HeaderLogo>
			</Header>
		</Root>
	)
}
